import DomElement from "editor/dom_element"
import InputField from "editor/fields/input_field"

class window.Editor extends DomElement
  SELECTOR: "[data-editor]"

  constructor: ->
    super(arguments...)

    return if @elem.data("editor-initialized")
    @elem.data("editor-initialized", true)

    @elem.addClass("editor--active")
    @elem.addClass("editor--pc") unless App.mobile
    @elem.find(Record::SELECTOR).each -> new Record(this)
    @elem.find(RecordList::SELECTOR).each -> new RecordList(this)
    @ajaxCount = 0

    # Respond to editor events
    @elem.on "editor:next",              => @tab(+1, true)
    @elem.on "editor:previous",          => @tab(-1, false)
    @elem.on "editor:resave",            => @unsavedElements().trigger("element:resave")
    @elem.on "field:keydown", (e)        => @onKeydown(e.detail.originalEvent, e.detail.domElement) && e.preventDefault()

    # Respond to ajax events
    @elem.on "record:ajaxStart", =>
      @ajaxCount += 1
      @trigger("editor:saving") if @ajaxCount > 0

    @elem.on "record:ajaxComplete", =>
      @ajaxCount -= 1
      if @unsavedElements().length
        @trigger("editor:failed")
      else if @ajaxCount == 0
        @trigger("editor:saved")

  tab: (direction, atStart) ->
    nextTabbable = @findNextTabbable(direction)
    nextTabbable.focus()
    nextTabbable.setCaret(atStart)

  findNextTabbable: (direction) ->
    allTabbables = @allTabbables()
    currentTabbableIndex = allTabbables.index(@currentField())
    nextTabbableIndex = currentTabbableIndex + direction
    nextInSelection = allTabbables.eq(nextTabbableIndex)
    unless nextInSelection.data("field") == "textarea"
      return nextInSelection

    childTextArea = nextInSelection.find("textarea:visible")
    if childTextArea
      childTextArea
    else
      nextInSelection

  currentField: -> @elem.find(":focus")
  allTabbables: -> @elem.find("#{InputField.SELECTOR}, a:tabbable, button:tabbable").filter(":visible")

  onKeydown: (e, field) ->
    if e.shiftKey
      switch e.which
        when $.ui.keyCode.TAB  then @tab(-1, false)
    else
      switch e.which
        when $.ui.keyCode.TAB  then @tab(+1, false)
        when $.ui.keyCode.UP   then @tab(-1, false) if field.atStart()
        when $.ui.keyCode.DOWN then @tab(+1, true) if field.atEnd()
