import DomElement from "editor/dom_element"
import RecordIdentifier from "editor/record_identifier"
import InputField from "editor/fields/input_field"
import TextField from "editor/fields/text_field"
import TextAreaField from "editor/fields/text_area_field"
import CheckBoxFieldGroup from "editor/fields/check_box_field_group"
import Helpers from "helpers"

class window.Record extends DomElement
  SELECTOR: "[data-record]"

  constructor: ->
    super(arguments...)
    @identifier = new RecordIdentifier(@elem)
    @fields = []

    # Build field groups and fields
    @elem.find(FieldGroup::SELECTOR).each -> new FieldGroup(this)

    # Build fields
    for field in [TextField, TextAreaField, FileField, CheckBoxFieldGroup]
      selector = field::SELECTOR || field.SELECTOR #TODO: Just use field.SELECTOR once TextField and FileField is migrated from coffeescript
      @elem.find(selector).each (i, elem) => @fields.push new field(elem) if @isClosest(elem)

    # Respond to events
    @elem.on "field:save", (e)         =>  @ifClosestTarget(e, => @update(e.detail.domElement))
    @elem.on "record:delete", (e)      =>  @ifClosestTarget(e, => @delete(e.originalEvent?.detail?.canDeleteLastRemaining))

  save: ->
    return if @persisted()
    @waitForParentId(=> @makeSaveRequest())

  makeSaveRequest: ->
    @saving = true
    @ajax "POST",
      data: @buildPostData(@fields)
      success: (id) =>
        @identifier.updateId(id)
        @setSavedStatus(true)
        @saving = false
        @trigger "record:persisted"
        @notifyChildRecords()
      error: =>
        @saving = false
        @setSavedStatus(false)

  persisted: -> @identifier.hasId()

  update: (field) ->
    @waitForId field.name, => @makeUpdateRequest(field)

  makeUpdateRequest: (field) ->
    value = field.value()
    @ajax "PUT",
      data: @buildPostData([field])
      success: (html) -> field.render(value, html)
      error: -> field.setSavedStatus(false)

  destroy: ->
    @elem.off "record:persisted"
    @waitForId "destroy", => @ajax "DELETE"

  waitForParentId: (callback) ->
    return callback.call() unless @parentRecord().present()
    return callback.call() if @parentRecord().hasId()

    @elem.off "parentRecord:persisted"
    @elem.on "parentRecord:persisted", -> callback.call()

  waitForId: (queue, callback) ->
    return callback.call() if @persisted()

    @save() unless @waitingForId()
    @elem.off "record:persisted.#{queue}"
    @elem.on "record:persisted.#{queue}", -> callback.call()

  waitingForId: ->
    @saving == true

  delete: (canDeleteLastRemaining) ->
    return if !canDeleteLastRemaining && @lastRemaining()

    @focusAdjacent() if @typing()
    @destroy()
    @trigger("record:deleted")
    @trash() # Keep as the last step as trashing would change the DOM structure

  lastRemaining: -> @elem.siblings().length == 0
  typing: -> @elem.find(":focus").is(":input, [data-field]")

  focusAdjacent: ->
    return @trigger("editor:next") if @elem.is(":first-child")

    @trigger("editor:previous")

  trash: -> @elem.html("").hide().appendTo @elem.closest(Editor::SELECTOR)

  ajax: (method, options = {}) ->
    @trigger("record:ajaxStart")
    Helpers.ajax(@getUrl(), method: method, data: options.data)
      .then(this.processResponse)
      .then (xhr) =>
        options.success?(xhr.responseText)
        this.triggerLegacyEvent("ajaxSuccess", xhr)
      .catch (xhr) =>
        options.error?()
        this.triggerLegacyEvent("ajaxError", xhr)
      .finally => @trigger("record:ajaxComplete")

  # https://github.com/cookpad/global-web/pull/23159
  processResponse: (response) ->
    xhrLike = { status: response.status, statusText: response.statusText, responseText: await response.text() }

    if (response.ok)
      xhrLike
    else
      Promise.reject(xhrLike)

  triggerLegacyEvent: (name, xhr) ->
    this.elem.trigger(name, [xhr])

  baseUrl : ->
    @_baseUrl = @parseUrl @elem.data("record") if @_baseUrl == undefined
    @_baseUrl

  getUrl: ->
    url = "#{@baseUrl.pathname}/#{@identifier.id()}#{@baseUrl.search}"
    url = "#{@baseUrl().pathname}/#{@identifier.id()}#{@baseUrl().search}"
    url = "/#{url}" unless url[0] == "/" # IE drops leading "/"...
    url

  parseUrl: (urlTemplate) ->
    parser = document.createElement("a")
    parser.href = @substituteParams(urlTemplate)
    parser

  substituteParams: (urlTemplate) ->
    urlTemplate.replace(/:parent_record_id/, @parentRecord().id())

  appendTo: (container) ->
    @elem.appendTo(container)
    @initNewRecord()

  insertAfter: (sibling) ->
    @elem.insertAfter $(sibling).closest(@SELECTOR)
    @initNewRecord()

  initNewRecord: ->
    @focus()
    @elem.on "element:resave", => @save()
    @buildChildRecords()
    @buildChildRecordLists()
    @trigger "record:added"

  buildPostData: (fields) ->
    fieldData = fields.reduce ((fieldData, field) ->
      Object.assign(fieldData, field.serialize())
    ), {}

    # Safari swallows rapid "identical" ajax requests
    { "#{@identifier.name()}": fieldData, timestamp: new Date().getTime() }

  buildChildRecords: ->
    @elem.find(@SELECTOR).each (i, elem) => new Record(elem) if @isParent(elem)

  buildChildRecordLists: ->
    @elem.find(RecordList::SELECTOR).each (i, elem) => new RecordList(elem) if @isParent(elem)

  isClosest: (elem) -> @elem.is($(elem.closest(@SELECTOR)))
  isParent: (elem) -> @elem.is($(elem).parents(@SELECTOR).first())
  notifyChildRecords: ->
    @elem.find(@SELECTOR).each (i, elem) => $(elem).trigger("parentRecord:persisted") if @isParent(elem)

  updateFields: (data) ->
    for field in @fields
      field.setValue(data[field.name]) if data.hasOwnProperty(field.name)

  focus: ->
    @elem.find(InputField.SELECTOR).each (_, elem) => elem.focus() unless (elem.dataset.notFocused == "true")
