export default class RecordIdentifier {
  constructor(elem) {
    this.elem = elem;
  }

  // Fish out names/ids from dom_id (about__recipe_324  = "name: 'recipe', id: 324", about__recipe = "name: 'recipe', id: nil")
  name() {
    if (this._isNew()) {
      return this._parts(1);
    }
    return this._parts(0, -1);
  }
  id() {
    if (this._isNew()) {
      return "";
    }
    return this._parts(-1);
  }

  // Write a new dom_id to the dom
  updateId(newId) {
    return this.elem.attr("id", this.name() + "_" + newId);
  }
  hasId() {
    return !this._isNew();
  }
  _isNew() {
    return isNaN(this._parts(-1));
  }
  _parts(start, end) {
    return this._domIdWithoutPrefix().split("_").slice(start, end).join("_");
  }
  _domIdWithoutPrefix() {
    return this.elem.attr("id").split("__").pop();
  }
}