export default class Rotate {
  constructor(imageEditor) {
    imageEditor["rotate"] = this.rotate.bind(imageEditor);
  }
  rotate(degree) {
    this.layer.find("Image").forEach(image => image.rotate(degree));
    this.imageDataURLOptions = {};
    this._imageDataURL(imageDataURL => {
      this.renderImage(imageDataURL);
      this._draw();
    });
  }
}