# Init sortables
$(document).on "turbo:load ajax:success", ->
  $("[data-behavior~=sortable]:not([data-sortable-axis-free])").sortable
    handle: "[data-behavior=handle]"
    axis: "y"
    forcePlaceholderSize: true
  $("[data-behavior~=sortable][data-sortable-axis-free]").sortable
    handle: "[data-behavior=handle]"
    forcePlaceholderSize: true

# Blur inputs to hide keyboard when sorting on touch screens
$(document).on "touchstart", "[data-behavior~=handle]", ->
  $(":focus").blur()

# Init editor
$(document).on "turbo:load", ->
  $("[data-editor]").each -> new Editor(this)

# Placeholders for non-form inputs
$(document).on "turbo:load ajax:success", ->
  $("[data-placeholder]").each ->
    $(this).addClass("placeholder") unless $.trim $(this).text()

# Wait for editor to finish saving on publish
$(document).on "click", "[data-behavior~=publish]", (e) ->
  if $("#saver").is(":visible")
    e.preventDefault()
    $(document).one "editor:saved", => Utils.submit $(this).closest("form")[0]

# Trigger resaving failed fields
$(document).on "click", "[data-behavior~=retry_unsaved]", (e) ->
  $($(this).attr("href")).trigger("editor:resave")
  e.preventDefault()
