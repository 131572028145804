const defaultFileUploadOptions = {
  formData: {},
  // Forces POST
  dataType: "json",
  // Skip resizing for Android non-Chrome browser and Opera
  // https://github.com/blueimp/jQuery-File-Upload/wiki/Client-side-Image-Resizing
  disableImageResize: /Android(?!.*Chrome)|Opera/.test(window.navigator && navigator.userAgent),
  // Resize images before upload, regardless of their size
  loadImageMaxFileSize: null,
  // Backend nginx's client_max_body_size is 15MB
  maxFileSize: 15000000,
  imageMaxWidth: 1080,
  imageMaxHeight: 1080,
  acceptFileTypes: /(\.|\/)(gif|jpe?g|png|bmp)$/i,
  processfail: (e, data) => alert(data.files[0].error),
  fail: (_e, _data) => alert(App.genericError),
  messages: {
    maxFileSize: App.fileIsTooLargeError,
    acceptFileTypes: App.fileTypeNotAllowedError,
    uploadedBytes: App.genericError,
    unknownError: App.genericError
    // these errors won't happen because we don't set 'minFileSize' or 'maxNumberOfFiles' options
    // minFileSize: App.genericError,
    // maxNumberOfFiles: App.genericError,
  }
};
const fileAjaxUpload = (container, url) => {
  var input = $(container).find("input[type=file]")[0];
  var callbacks = {
    start: () => {
      $(container).trigger("image:start");
    },
    error: () => {
      $(container).trigger("image:error");
    },
    done: (e, data) => {
      $(container).trigger("image:change", data.result);
    }
  };
  var optionsFromArgs = {
    url: url,
    dropZone: container
  };
  container.classList.add("droppable");
  input.setAttribute("accept", "image/*");
  var optionsForImageSize = {};
  var maxSize = container.dataset.imageUploadMaxSize;
  if (maxSize) {
    optionsForImageSize.imageMaxWidth = maxSize;
    optionsForImageSize.imageMaxHeight = maxSize;
  }
  $(input).fileupload({
    ...defaultFileUploadOptions,
    ...optionsFromArgs,
    ...optionsForImageSize,
    ...callbacks
  });
};
export default fileAjaxUpload;