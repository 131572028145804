import loading from "loading"

# On change ajax in new html, filtering out and replacing only the target
$(document).on "change", "[data-behavior~=refresh_on_change]", (e) ->
  $(this).data("xhr")?.abort()
  targets = $(this).data("target").split(" ")

  targets.forEach (target) ->
    loading($(target)[0])

  xhr = $.get $(this).attr("action"), $(this).serialize(), (data) ->
    targets.forEach (target) ->
      $(target).replaceWith $(data).find(target)
  $(this).data "xhr", xhr
