import DomElement from "editor/dom_element"
import Helpers from "helpers"

class window.RecordList extends DomElement
  SELECTOR: "[data-record-list]"

  constructor: ->
    super(arguments...)
    @templates = @buildTemplates()
    @container = @elem.children("[data-record-list-container]")

    @elem.on "recordList:append", (e)      => @ifClosestTarget(e, => @append(e))
    @elem.on "record:persisted", (e)       => @ifClosestTarget(e, => @savePositions())
    @elem.on "sortupdate", (e)             => @ifClosestTarget(e, => @savePositions())
    @elem.on "field:keydown", (e)   =>
      @ifClosestTarget(e, => @onKeydown(e.detail.originalEvent, e.detail.domElement)) && e.preventDefault() && e.stopPropagation()
    @elem.on "textField:paste", (e) => @ifClosestTarget(e, => @onPaste(e.detail.domElement))
    @elem.on "record:append", (e) => @addRecord(e.detail.record)

  addRecord: (sibling, recordType = "default", save = true, data) ->
    record = new Record(@templates[recordType])
    if sibling then record.insertAfter(sibling) else record.appendTo(@container)
    record.updateFields(data) if data
    record.save() if save
    record

  append: (e) ->
    @addRecord null, e.detail.recordType, e.detail.save, e.detail.data

  savePositions: ->
    return unless @container.data("sort-url")

    url = @container.data("sort-url").replace(/:parent_record_id/, @parentRecord().id())
    ids = @container.sortable("toArray").map (domId) -> domId.split("_").pop()

    @controller?.abort()
    @controller = new AbortController()

    Helpers.ajax url,
      signal: @controller.signal
      method: "PUT"
      data: { order: ids }

  onKeydown: (e, field) ->
    return if e.shiftKey
    switch e.which
      when $.ui.keyCode.ENTER     then @enter(field)
      when $.ui.keyCode.BACKSPACE then field.trigger("record:delete") if @deletable(e, field)
      when $.ui.keyCode.DELETE    then field.trigger("record:delete") if @deletable(e, field)

  enter: (field) ->
    if field.isSplittable
      @splitField field
    else if field.isReturnable
      @addRecord(field.elem)
    else if field.isNextable
      field.elem.parent().next().find("div[data-field]").focus()

  splitField: (field) ->
    afterText = field.editableTextAfterCursor()
    beforeText = field.editableTextBeforeCursor()

    newRecord = @addRecord(field.elem)

    unless field.elem.data().addSiblingDisabled
      field.setValue(beforeText)
      newRecord.fields[0].value(afterText)

  onPaste: (field) ->
    lines = field.value().split("\n")
    field.value lines.shift()
    if lines.length > 0
      newRecord = @addRecord(field.elem)
      newField = newRecord.fields[0]
      newField.value lines.join("\n")
      @onPaste newField

  isClosest: (elem) -> @elem.is($(elem.closest(@SELECTOR)))

  buildTemplates: ->
    templates = {}
    @elem.children("template").each (_, template) ->
      type = template.dataset.type
      templates[type] = template.content.children[0].outerHTML
    templates
  
  deletable: (e, field) ->
    name = field.name
    return @stepDeletable(e, field) if name == "description"

    return field.isEmpty() unless name == "separateIngredientName" || name == "separateIngredientQuantity"

    sibling_text = field.elem.parent().siblings().find("div[data-field]").text().trim()
    return field.isEmpty() && sibling_text.length == 0

  stepDeletable: (e, field) ->
    attachments = field.elem.parent().parent().siblings().find("div[data-record-list-container] > :not(#new_step_attachment)").children()

    return field.isEmpty() && attachments.length == 0 
