import DomElement from "editor/dom_element"
import InputField from "editor/fields/input_field"

class window.FieldGroup extends DomElement
  SELECTOR: "[data-field-group]"

  constructor: ->
    super(arguments...)
    @elem.addClass("field-group")

    # Toggle body class, needs to happen without delay to prevent Android from glitching
    @elem.on "field:focusing", -> $("body").addClass("focusing")
    @elem.on "field:blurring", -> $("body").removeClass("focusing")

    # Clicking vaguely inside group but outside any text field/link focuses first text field
    @elem.on "mousedown touchstart", (e) =>
      @lastClicked = e.target

    @elem.on "click", (e) =>
      @lastClicked ||= e.target
      return if $(@lastClicked).closest(InputField.SELECTOR).length # clicking text field
      return if $(@lastClicked).closest("a, input").length # clicking links/handles/inputs
      @elem.find(InputField.SELECTOR).first().focus()
