import Field from "editor/fields/field"
import ImageFileHandler from "editor/file_handlers/image_file_handler"
import VideoFileHandler from "editor/file_handlers/video_file_handler"
import dragDrop from "drag-drop"
import loading from "loading"

class window.FileField extends Field
  SELECTOR: "[data-field=file]"

  constructor: ->
    super(arguments...)

    @elem.on "image:change", (e, result) => @onFileUploaded("image", result.id) # To support uploads based on data-ajax-upload

    dragDrop(@elem[0], (files) => @onFilesAdded(files))
    @elem.on "fileUploaded", (e) => @onFileUploaded(e.detail.type, e.detail.id)
    @elem.on "change", (e) => @onFilesAdded(Array.from(e.target.files))

  getValue:         -> @elem.data("field-value")
  setValue: (value) -> @elem.data("field-value", value)
  render: -> super(arguments...) && loading(@elem[0], false)

  serialize: ->
    return {} unless @value()

    key = @value()["type"] + "_id"
    value = @value()["id"]
    { "#{key}": value }

  onFilesAdded: (files) ->
    @handleFile(files.shift())

  onFileUploaded: (type, id) ->
    @value({ id: id, type: type })
    @save()

  handleFile: (file) ->
    if file.type.startsWith("image/")
      @handleImageFile(file)
    else if file.type.startsWith("video/")
      @handleVideoFile(file)
    else
      alert(App.genericError)

  handleImageFile: (file) ->
    new ImageFileHandler(@elem[0], file).run()

  handleVideoFile: (file) ->
    new VideoFileHandler(@elem[0], file).run()
