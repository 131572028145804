import fileAjaxUpload from "file_ajax_upload"
import loading from "loading"

# Init image containers containing ajax file inputs
$(document).on "turbo:load ajax:success record:added", ->
  $("[data-ajax-upload]").each ->
    fileAjaxUpload this, $(this).data("ajax-upload")

# Show drop targets when dragging files into browser
$(document).on "dragbetterenter", ->
  $(".droppable").addClass("droppable--dropping")

$(document).on "dragbetterleave", ->
  Utils.delay 10, -> # Avoid flickering when dropping on placeholder
    $(".droppable").removeClass("droppable--dropping")

# Show loading indicator when upload starts
$(document).on "image:start", "[data-ajax-upload]", -> loading(this)

# Stop loading indicator on error
$(document).on "image:error", "[data-ajax-upload]", -> loading(this, false)

# Disable submit button when image inside a form is uploading
$(document).on "image:start", "form", ->
  $(this).find("input[type=submit]").prop("disabled", true)

# Enable submit button when image inside a form is changed
$(document).on "image:change", "form", ->
  $(this).find("input[type=submit]").removeAttr("disabled")

# Update input field and image html when inside a form with a hidden field
$(document).on "image:change", "form [data-ajax-upload]", (e, result) ->
  hiddenField = $(this).closest("form").find("input[id$=image_id]")
  if hiddenField.length
    hiddenField.val(result.id)
    $(this).replaceWith(result.html)

# On delete fetch a new image from ajax-upload url and trigger image:change
$(document).on "image:delete", "[data-ajax-upload]", ->
  loading(this)
  $.get $(this).data("ajax-upload"), (result) =>
    $(this).trigger("image:change", result)

# Trigger delete image events
$(document).on "click", "[data-behavior~=delete_image]", (e) ->
  $(this).trigger "image:delete"
  e.preventDefault()
