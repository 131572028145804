# Appropriated from http://brianmhunt.github.io/articles/taming-contenteditable/ and https://gist.github.com/al3x-edge/1010364
$.fn.extend
  setCaret: (atStart = true) ->
    if @attr("contenteditable") # contenteditable
      range = @editableRange()
      range.collapse(atStart)
      selection = window.getSelection()
      selection.removeAllRanges()
      selection.addRange(range)
    else if this[0]?.setSelectionRange # normal input field
      pos = if atStart then 0 else @val().length
      this[0].setSelectionRange(pos, pos)
    this

  editableCaretAtStart:  ->
    @editableTextBeforeCursor().length == 0

  editableTextBeforeCursor: ->
    range = @editableRange()
    caret = @editableCaret()
    range.setEnd(caret.startContainer, caret.startOffset)
    range.toString().trim()

  editableCaretAtEnd:  ->
    @editableTextAfterCursor().length == 0

  editableTextAfterCursor: ->
    range = @editableRange()
    caret = @editableCaret()
    range.setStart(caret.endContainer, caret.endOffset)
    range.toString().trim()

  editableCaret: -> window.getSelection().getRangeAt(0)
  editableRange: ->
    range = document.createRange()
    range.selectNodeContents(@[0])
    range
